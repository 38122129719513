import NavHeader from './NavHeader';
import './Resume.css';

function Heading() {
    return (
        <div className='Heading'>
            <h2>Nicholas Padilla</h2>
        </div>
    );
}

function AboutMe() {
    return (
        <div className='AboutMe'>
            <h2 className='Heading'>About Me</h2>
            <p>
                An experienced engineer with experience in software development, robotics, scientific research, and high-performance computing.
                I am currently a Robotics Masters student at the University of Minnesota, Twin Cities.
                I am pursuing a career in robotics and artificial intellegence.
            </p>
        </div>
    )
}

function Skills() {
    return (
        <div className='Skills'>
            <h2 className='Heading'>Skills</h2>
            <div>
                <Skill name="Python" num_stars="4" />
                <Skill name="C/C++" num_stars="2" />
                <Skill name="Robotics" num_stars="3" />
                <Skill name="Analytics" num_stars="4" />
                <Skill name="Deep Learning" num_stars="3" />
                <Skill name="DevOps" num_stars="3" />
            </div>
        </div>
    )
}

function Skill(props) {
    return (
        <div className='Skill'>
            <h3 className='Name'>{props.name}</h3>
            <StarRating className="Rating" num_stars={props.num_stars} />
        </div>
    )
}

function StarRating(props) {
    return (
        <div>
            {[...Array(parseInt(props.num_stars))].map(() => {
                return (
                    <span className='FullStar'>
                        &#9733;
                    </span>
                );
            })}
            {[...Array(5 - props.num_stars)].map(() => {
                return (
                    <span className='EmptyStar'>
                        &#9733;
                    </span>
                );
            })}
        </div>
    )
}

function WorkExperience() {
    return (
        <div className='WorkExperience'>
            <h2 className='Heading'>Work Experience</h2>
            <Job
                company="University of Minnesota"
                jobTitle="Software Engineer in Test"
                startDate="May 2021"
                endDate="Nov 2022"
                description="
                Working on the Research Administration Services team, I was responsible for developing and maintaining the adminsitions software 
                for research compliance and grants applications. Main accomplishments include containerizing legacy applications, DevOps, and,
                developing a web UI test framework for a third-party grant application.
                "/>
            <Job
                company="Digilabs"
                jobTitle="Robotics Engineer"
                startDate="Oct 2020"
                endDate="Apr 2019"
                description="
                Diglabs was a Startup Incubator, that hired me as a robotics engineer at two robotics startups: Rover Robotics and Poultry Patrol.
                I worked on developing robotics platforms, developing ROS and ROS2 drivers, and computer visions.
                "/>
            <Job
                company="Howard Hughes Medical Institute"
                jobTitle="Connectome Annotator"
                startDate="Oct 2017"
                endDate="Apr 2019"
                description="
                I was a member of the Connectome annotation team I was responsible for annotating and analysizing the connectome of the Drosophila brain.
                I worked with Lab heads to manually explore and annotate the connectome dataset and provide data analysis and visualization.
                He I gained experience in scientific research, data analysis, and data visualization.
                "/>
            <Job
                company="Cray Supercomputing"
                jobTitle="Software Engineer in Test"
                startDate="Oct 2015"
                endDate="Jan 2017"
                description="
                As a member of the Opertating Systems test team, my focuses was on validating system software and hardware integrations.
                This included creating test plans, automated tests, and kernel level debugging.
                The most notable project I worked on was creating a test plan for the migration from x86 to ARM cabinet network/power controller.
                "/>
        </div>
    )
}

function Job(props) {
    return (
        <div className='Job'>
            <h3>{props.company}</h3>
            <h4>{props.jobTitle}</h4>
            <h4>{props.startDate} - {props.endDate}</h4>
            <p>{props.description}</p>
        </div>
    )
}

function Education() {
    return (
        <div className='Education'>
            <h2 className='Heading'>Education</h2>
            <Program school="University of Minnesota: Twin Cities" Degree="MS Robotics" startDate="September 2022" endDate="Current" gpa="4.0" />
            <Program school="University of Minnesota: Twin Cities" Degree="BS Computer Science" startDate="September 2012" endDate="May 2016" />
        </div>
    )
}

function Program(props) {
    var isGPA = props.gpa ? true : false;
    if (isGPA)
        return (
            <div className='Program'>
                <h3>{props.school}</h3>
                <h4>{props.Degree}</h4>
                <h4>{props.startDate} - {props.endDate}</h4>
                <h4>GPA: {props.gpa}</h4>
            </div>
        )
    else
        return (
            <div className='Program'>
                <h3>{props.school}</h3>
                <h4>{props.Degree}</h4>
                <h4>{props.startDate} - {props.endDate}</h4>
            </div>
        )
}

function Resume() {
    window.document.body.style.overflow = 'scroll';
    return (
        <div className='Resume'>
            <div className="NavHeader">
                <NavHeader page_name="My Resume" />
            </div>
            <div className="ResponsiveColums">
                <div>
                    <Heading />
                    <AboutMe />
                    <Skills />
                    <Education />
                </div>
                <div>
                    <WorkExperience />
                </div>
            </div>
        </div>
    );
}

export default Resume;