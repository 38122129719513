import "./NavHeader.css";
import React from 'react';
import {ReactComponent as PortfolioLogo} from '../assets/np_logo_whitish.svg';
import NavigationMenu from './NavigationMenu';

function NavHeader(props) {
    return (
        <div className="NavHeader">
            <PortfolioLogo className="PortfolioLogo"/>
            <h1 className="PageTitle">
                {props.page_name}
            </h1>
            <NavigationMenu fontSize="16"/>
        </div>
    );
}

export default NavHeader;