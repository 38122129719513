import './Homepage.css';
import Header from './Header';
import AnimatedBackground from './AnimatedBackground';
import NavigationMenu from './NavigationMenu';

function Homepage() {
  window.document.body.style.overflow = 'hidden';
  return (
    <div className="Homepage">
      <Header/>
      <NavigationMenu fontSize='16' />
      <AnimatedBackground/>
    </div>
  );
}

export default Homepage;
