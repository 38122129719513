import './AnimatedBackground.css';
import React, { useRef, useCallback, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber'
import { BufferGeometry, BufferAttribute, PerspectiveCamera, Color } from 'three';
import { Vector3 } from 'three';
import { ResizeObserver } from '@juggle/resize-observer'

function AnimatedBackground() {
    const camera = new PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.translateY(-80)
    camera.translateZ(20)
    camera.rotateOnWorldAxis(new Vector3(1, 0, 0), Math.PI * (5 / 16))
    camera.rotateOnWorldAxis(new Vector3(0, 0, 1), Math.PI * (1 / 16))
    return (
        <div className="animated-background">
            <Canvas
                style={{ width: "100vw", height: "100vh" }}
                camera={camera}
                resize={{ polyfill: ResizeObserver }}>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <PointCloud position={[1.2, 0, 0]} />
            </Canvas>
        </div>
    );
}

function PointCloud() {
    const bufferRef = useRef();

    const fX = 6.0
    const fT = 2.0
    const a  = 5.0

    const waveGraph = useCallback((x, y, t) => {
        return a * Math.sin( x / fX + t / fT) * Math.sin( y / fX + t / fT);
    }, [fX, fT, a]);

    const xSize = 200
    const ySize = 150
    const xyScale = 1.6

    let positions = useMemo(() => {
        const positions = [xSize * ySize * 3]
        for (let x = 0; x < xSize; x++) {
            for (let y = 0; y < ySize; y++) {
                positions[3 * x + 3 * y * xSize] = xyScale * x - (xSize * xyScale / 2)
                positions[3 * x + 3 * y * xSize + 1] = xyScale * y - (ySize * xyScale / 2)
                positions[3 * x + 3 * y * xSize + 2] = waveGraph(x, y, 0)
            }
        }

        return new Float32Array(positions)
    }, [xSize, ySize,xyScale, waveGraph])

    const geometry = new BufferGeometry()

    useFrame(({ clock }) => {
        const t = clock.getElapsedTime()
        for (let x = 0; x < xSize; x++) {
            for (let y = 0; y < ySize; y++) {
                positions[3 * x + 3 * y * xSize + 2] = waveGraph(x, y, t)
            }
        }
        geometry.setAttribute('position', new BufferAttribute(positions, 3))
        geometry.computeVertexNormals()
    })

    return (
        <points 
            ref={bufferRef}
            geometry={geometry}>
            <pointsMaterial
                attatch="material"
                color={new Color(0xFFFFFF)}
                size={0.075}
            />
        </points>
    )
}

export default AnimatedBackground;
