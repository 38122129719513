import './App.css';
import Homepage from './components/Homepage';
import Resume from './components/Resume';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage/>,
  },
  {
    path: "resume",
    element: <Resume/>,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
