import './Header.css';

function Header() {
  return (
    <div className="Header">
        <p className='Text'>
          🤝 Hi!
          I'm <span className="Highlight">nick padilla</span>.
        </p>
        <p className='Subtext'>
          I'm an engineer and masters student at the University of Minneosta.<br/>
          Here you'll find my CV and samples of robotics an machine learning projects.
        </p>
    </div>
  );
}

export default Header;