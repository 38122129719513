import './NavigationMenu.css';
import {Link} from "react-router-dom"

function NavigationMenu(props) {
  return (
    <div className='NavigationMenu' style={{ fontSize: parseInt(props.fontSize) }}>
        <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>home</Link>
        &bull;
        <Link to="/resume" style={{ color: 'inherit', textDecoration: 'inherit' }}>resume</Link>
    </div>
  );
}

export default NavigationMenu;